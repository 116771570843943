import { API_URL } from '@lib/constants/environment';
import { IProduct } from '@types';
import { handleError } from '@utils/error-handling';

export async function getCartData(products: string[]): Promise<IProduct[]> {
  const res = await fetch(`${API_URL}/sales/cart`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ products })
  });
  return handleError<IProduct[]>(res);
}
